import React, { useState, useEffect, useRef, useCallback } from 'react';
import * as pdfjsLib from 'pdfjs-dist/build/pdf';
import "./ViewPDF.css";
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from './AuthProvider';

pdfjsLib.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/4.6.82/pdf.worker.mjs`;

const injectCSS = (url) => {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = url;
    document.head.appendChild(link);
};

const ViewDetails = () => {
    const { fileName } = useParams();
    const [numPages, setNumPages] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const canvasRef = useRef(null);
    const [pdfDocument, setPdfDocument] = useState(null);
    const [pageDetails, setPageDetails] = useState(null);
    const [scale, setScale] = useState(1);
    const [editable, setEditable] = useState(false);
    const { user } = useAuth();

    useEffect(() => {
        injectCSS('https://cdnjs.cloudflare.com/ajax/libs/pdf.js/4.6.82/pdf_viewer.min.css');
        fetchPDF(fileName);
    }, [fileName]);

    const fetchPDF = async (fileName) => {
        try {
            const response = await axios.get(`/pdf/${fileName}`, { responseType: 'arraybuffer' });
            const pdfData = response.data;
            try {
                const pdf = await pdfjsLib.getDocument({ data: pdfData }).promise;
                setPdfDocument(pdf);
                setNumPages(pdf.numPages);
                setCurrentPage(1);
            } catch (pdfError) {
                console.error('Error creating PDF document:', pdfError);
            }
        } catch (error) {
            console.error('Error fetching PDF:', error);
        }
    };

    const getPDFDetails = async (fileName, pageNumber) => {
        if (!fileName) return;
        try {
            const response = await axios.get(`/filedetails/${fileName}`, {
                params: { page_number: pageNumber }
            });
            setPageDetails(response.data);
        } catch (error) {
            console.error('Error fetching PDF details:', error);
            if (error.response) {
                console.error('Response data:', error.response.data);
                console.error('Response status:', error.response.status);
            }
        }
    };

    const renderPage = useCallback(async () => {
        if (pdfDocument && canvasRef.current) {
            try {
                const page = await pdfDocument.getPage(currentPage);
                const viewport = page.getViewport({ scale });
                const canvas = canvasRef.current;
                const context = canvas.getContext('2d');
                canvas.height = viewport.height;
                canvas.width = viewport.width;

                const renderContext = {
                    canvasContext: context,
                    viewport: viewport,
                };

                await page.render(renderContext).promise;
            } catch (error) {
                console.error('Error rendering page:', error);
            }
        }
    }, [pdfDocument, currentPage, scale]);

    useEffect(() => {
        renderPage();
        getPDFDetails(fileName, currentPage);
    }, [renderPage, fileName, currentPage]);

    const handlePreviousPage = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    };

    const handleNextPage = () => {
        setCurrentPage((prevPage) => Math.min(prevPage + 1, numPages));
    };

    const zoomIn = () => {
        setScale((prevScale) => Math.min(prevScale + 0.25, 3));
    };

    const zoomOut = () => {
        setScale((prevScale) => Math.max(prevScale - 0.25, 0.5));
    };

    const handleEditable = () => {
        setEditable(!editable);
    };

    const handleInputChange = (key, newValue) => {
        setPageDetails((prevDetails) => ({
            ...prevDetails,
            [key]: newValue,
        }));
    };

    const handleReferenceChange = (key, newValue) => {
        setPageDetails((prevDetails) => ({
            ...prevDetails,
            References: {
                ...prevDetails.References,
                [key]: newValue,
            },
        }));
    };

    const handleUpdateDetails = async () => {
        try {
            const response = await axios.post(`/updatefiledetails/${fileName}`, {
                page_number: currentPage,
                details: pageDetails
            }, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.status === 200) {
                setPageDetails(response.data);
                console.log(response.data);
                setEditable(false);
            } else {
                console.error('Failed to update PDF details');
            }
        } catch (error) {
            console.error('Error updating PDF details:', error);
        }
    };

    return (
        <div className="flex flex-row justify-between h-screen w-full">
            <div className="w-8/12 flex flex-col items-center justify-start p-4">
                {pdfDocument && (
                    <>
                        <div className="flex items-center space-x-4 mb-4">
                            <svg
                                onClick={zoomOut}
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                className="w-6 h-6 cursor-pointer"
                            >
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12H9" />
                            </svg>
                            <svg
                                onClick={zoomIn}
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                className="w-6 h-6 cursor-pointer"
                            >
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12h-3m0 0H9m3 0v-3m0 3v3" />
                            </svg>
                            <button onClick={handlePreviousPage} disabled={currentPage === 1} className="bg-gray-300 hover:bg-gray-400 text-white font-bold py-2 px-2 rounded">
                                &lt;Page
                            </button>
                            <button onClick={handleNextPage} disabled={currentPage === numPages} className="bg-gray-300 hover:bg-gray-400 text-white font-bold py-2 px-2 rounded">
                                Page &gt; 
                            </button>
                        </div>
                        <canvas ref={canvasRef} style={{ marginTop: '20px', border: '1px solid black' }} className="w-full h-auto"></canvas>
                    </>
                )}
            </div>
            
            <div className="w-3/12 bg-gray-100 p-4 h-full overflow-auto">
                <h2 className="text-xl font-bold mb-4">PDF Details: {fileName} (Page {currentPage})</h2>
                {pageDetails && (
                    <div className="mb-4">
                        {Object.entries(pageDetails).map(([key, value]) => {
                            if (key !== "page_number" && key !== "References") {
                                return (
                                    <div key={key} className="mb-2">
                                        <label className="block text-gray-700 text-sm font-bold mb-2">{key}</label>
                                        <input
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            type="text"
                                            value={value || ""}
                                            onChange={(e) => handleInputChange(key, e.target.value)}
                                            disabled={!editable}
                                        />
                                    </div>
                                );
                            }
                            return null;
                        })}

                        {pageDetails.References && (
                            <div className="mb-4">
                                <h3 className="text-lg font-bold mb-2">References</h3>
                                {Object.entries(pageDetails.References).map(([key, value]) => (
                                    <div key={key} className="mb-2">
                                        <label className="block text-gray-700 text-sm font-bold mb-2">{key}</label>
                                        <input
                                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                            type="text"
                                            value={value || ""}
                                            onChange={(e) => handleReferenceChange(key, e.target.value)}
                                            disabled={!editable}
                                        />
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                )}
                
                <button onClick={handleEditable} className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
                    {editable ? 'Cancel' : 'Edit'}
                </button>
                {editable && (
                    <button onClick={handleUpdateDetails} className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded ml-2">
                        Save
                    </button>
                )}
            </div>
        </div>
    );
};

export default ViewDetails;
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthProvider';
import axios from 'axios';

function ViewResults() {
    const [results, setResults] = useState([]);
    const navigate = useNavigate();
    const { user } = useAuth();

    useEffect(() => {
        axios.get('/results')
            .then(response => {
                setResults(response.data.data);
            })
            .catch(error => {
                console.error('Error fetching results:', error);
            });
    }, []);

    const handleDetailsButtonClick = (fileName) => {
        navigate(`/details/${fileName}`);
    }

    return (
        <div className="w-full max-w-7xl mx-auto p-6 space-y-6">
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-2xl font-bold text-gray-800 dark:text-gray-200">Results</h2>
                <a 
                    href="/results/csv" 
                    className="py-2 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800"
                >
                    Download CSV
                    <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"></path>
                    </svg>
                </a>
            </div>
            
            <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 text-center border border-gray-300">
                        <tr>
                            <th scope="col" className="px-6 py-3">File Name</th>
                            <th scope="col" className="px-6 py-3">Shipping Address</th>
                            <th scope="col" className="px-6 py-3">Consignee Address</th>
                            <th scope="col" className="px-6 py-3">Bill To Address</th>
                            <th scope="col" className="px-6 py-3">References</th>
                            <th scope="col" className="px-6 py-3">
                                <span className="sr-only">Actions</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {results.map((result, index) => (
                            <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                <td className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">{result.file_name}</td>
                                <td className="px-6 py-4">{result.ship_from_address || ''}</td>
                                <td className="px-6 py-4">{result.consignee_address || ''}</td>
                                <td className="px-6 py-4">{result.bill_to_address|| ''}</td>
                                <td className="px-6 py-4">
                                    {<ul className="list-disc list-inside">
                                        {Object.entries(result.References).map(([key, value], index) => (
                                            <li key={index}>
                                            <strong>{key}</strong>: {value}
                                            </li>
                                        ))}
                                    </ul>
                                    }
                                </td>
                                <td className="px-6 py-4 text-right">
                                    <button
                                        onClick={() => handleDetailsButtonClick(result.file_name)}
                                        className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                                    >
                                        Details
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default ViewResults;